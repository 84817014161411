<template>
  <div class="container flex flex-col flex-1">
    <div class="flex flex-col flex-1">
      <b-overlay
        :show="loading"
        :opacity="0.85"
        variant="white"
        spinner-variant="primary"
        rounded="sm"
        blur="none"
        class="flex flex-col flex-1"
        overlay-tag="div"
      >
        <ModalQRCode
          v-if="appTInfo && appTInfo.code"
          :isShowQr.sync="isShowQr"
          :codeVictim.sync="appTInfo && appTInfo.code || ''"
          :title.sync="qrCodeTitle"
        ></ModalQRCode>

        <h1 class="mt-3 mt-md-5 mb-3 text-center text-pri-900">Lịch Khám</h1>

        <div v-if="appTInfo">
          <div
            v-if="appTInfo.status === APPOINTMENT_STATUS.CODE.CLINIC_REJECTED || appTInfo.status === APPOINTMENT_STATUS.CODE.PATIENT_CANCELLED"
          >
            <b-alert show variant="danger" class="text-center">
              <div>Lịch khám đã bị hủy</div>
              <div v-if="appTInfo.reject_reason">{{ appTInfo.reject_reason }}</div>
            </b-alert>
          </div>
          <div>
            <b-row class="align-start">
              <b-col cols="12" md="6" class="mb-4 mb-md-0">
                <b-card class="product-add-wrapper border h-full">
                  <b-row class="items-center mb-4">
                    <b-col cols="6" lg="7">
                      <div class="uppercase font-bold text-pri-900 text-center">
                        <div class="mb-2">SỐ THỨ TỰ</div>
                        <div class="text-6xl">{{ appTInfo.line_number }}</div>
                      </div>
                    </b-col>
                    <b-col cols="6" lg="5">
                      <div class="text-center">
                        <QRCode
                          v-if="appTInfo && appTInfo.code"
                          :value="`LH-${appTInfo && appTInfo.code}`"
                          :size="100"
                        ></QRCode>
                        <div
                          class="font-bold text-pri-900 whitespace-nowrap"
                        >{{ `LH-${appTInfo.code}` }}</div>
                      </div>
                    </b-col>
                  </b-row>

                  <!-- User Info: Thông tin lịch khám -->
                  <b-row>
                    <!-- Field: name clinic -->
                    <b-col cols="12" md="12" class="mb-2">
                      <div class="flex items-center ml-2 mr-2 mb-4">
                        <i class="el-icon-school" style="font-size: 40px; color: #20409B;" />
                        <p class="fs-20 ml-2 mb-0" style="color: #20409B;">{{appTInfo.clinic.name}}</p>
                      </div>
                    </b-col>

                    <b-col cols="12" md="12" class="mb-2">
                      <div class="flex items-center ml-2 mr-2 mb-4">
                        <i class="el-icon-first-aid-kit" style="font-size: 40px; color: #20409B;" />
                        <p
                          class="fs-20 ml-2 mb-0"
                          style="color: #20409B;"
                        >{{appTInfo.consulting_room.name}}</p>
                      </div>
                    </b-col>

                    <b-col cols="12" md="12" class="mb-2">
                      <div class="flex items-center ml-2 mr-2 mb-4">
                        <i
                          class="el-icon-location-information"
                          style="font-size: 40px; color: #20409B;"
                        />
                        <p
                          class="fs-20 ml-2 mb-0"
                          style="color: #20409B;"
                        >{{appTInfo.clinic.address}}</p>
                      </div>
                    </b-col>

                    <!-- Field: time -->
                    <b-col cols="12" md="12">
                      <div class="flex items-center ml-2 mr-2">
                        <img
                          style="max-width: 100%; height: 40px;"
                          src="../../../assets/images/medicalRegister/calendar_icon.png"
                          alt
                        />
                        <div class="ml-2 mb-0">
                          <p class="fs-20 mb-0" style="color: #424242;">Thời gian</p>
                          <p
                            class="fs-20 mb-0"
                            style="color: #20409B;"
                          >{{appUtils.formatGroupDateTime(appTInfo.start_time)}}</p>
                        </div>
                      </div>
                    </b-col>
                    <!-- Field: slot -->
                    <!-- <b-col cols="12" md="12">
                      <div class="flex items-center ml-2 mr-2 mb-4">
                        <img
                          style="max-width: 100%; height: 40px;"
                          src="../../../assets/images/medicalRegister/clock_icon.png"
                          alt
                        />
                        <div class="ml-2 mb-0">
                          <p class="fs-20 mb-0" style="color: #424242;">Thời lượng</p>
                          <p
                            class="fs-20 mb-0"
                            style="color: #20409B;"
                          >{{ appTInfo.slot_duration }} phút</p>
                        </div>
                      </div>
                    </b-col>-->
                  </b-row>
                </b-card>
              </b-col>
              <b-col cols="12" md="6">
                <b-card class="product-add-wrapper border h-full">
                  <!-- User Info: Input Fields -->
                  <b-row class="h-full">
                    <!-- Field: Address -->
                    <b-col cols="12" md="12" class="flex flex-col justify-between">
                      <div class="flex items-center ml-2 mr-2 mb-4">
                        <img
                          style="max-width: 100%; height: 40px;"
                          src="../../../assets/images/medicalRegister/profile_icon.png"
                          alt
                        />
                        <p class="fs-20 ml-2 mb-0" style="color: #424242;">Bệnh nhân</p>
                        <p class="fs-20 ml-auto p-2 mb-0" style="color: #20409B;">
                          <span>{{ appTInfo.person && appTInfo.person.name }}</span>
                          <span class="mx-1">-</span>
                          <span
                            class="capitalize"
                          >{{ appTInfo.person && appUtils.getAges(appTInfo.person.birthday) }}</span>
                        </p>
                      </div>
                      <div class="flex items-center ml-2 mr-2 mb-4">
                        <img
                          style="max-width: 100%; height: 40px;"
                          src="../../../assets/images/medicalRegister/doctor_icon.png"
                          alt
                        />
                        <p class="fs-20 ml-2 mb-0" style="color: #424242;">Bác sĩ</p>
                        <p
                          class="fs-20 ml-auto p-2 mb-0"
                          style="color: #20409B;"
                        >{{ appTInfo.doctor && appTInfo.doctor.User && appTInfo.doctor.User.name }}</p>
                      </div>
                      <div class="flex items-center ml-2 mr-2 mb-4">
                        <i class="el-icon-chat-square" style="font-size: 40px; color: #20409B;" />
                        <p class="fs-20 ml-2 mb-0" style="color: #424242;">Lý do khám</p>
                        <p
                          class="fs-20 ml-auto p-2 mb-0"
                          style="color:#20409B;"
                        >{{ appTInfo.appt_reason }}</p>
                      </div>
                      <div class="flex items-center ml-2 mr-2 mb-4">
                        <i class="el-icon-tickets" style="font-size: 40px; color: #20409B;" />
                        <p class="fs-20 ml-2 mb-0" style="color: #424242;">Trạng thái hóa đơn</p>
                        <div
                          v-if="formatterInvoiceStatus === 3"
                          class="text-box-success ml-auto p-2 mb-0"
                        >
                          <p
                            class="mb-0 fs-20"
                            style="color:#08B58B;"
                          >{{ mapStatusInvoiceName(formatterInvoiceStatus) }}</p>
                        </div>
                        <div
                          v-if="formatterInvoiceStatus===1"
                          class="text-box-fail ml-auto p-2 mb-0"
                        >
                          <p
                            class="mb-0 fs-20"
                            style="color:#F2471D;"
                          >{{ mapStatusInvoiceName(formatterInvoiceStatus) }}</p>
                        </div>
                        <div
                          v-if="formatterInvoiceStatus===4"
                          class="text-box-fail ml-auto p-2 mb-0"
                        >
                          <p
                            class="mb-0 fs-20"
                            style="color:#F2471D;"
                          >{{ mapStatusInvoiceName(formatterInvoiceStatus) }}</p>
                        </div>
                        <div
                          v-if="formatterInvoiceStatus === 2"
                          class="text-box-pending ml-auto p-2 mb-0"
                        >
                          <p
                            class="mb-0 fs-20"
                            style="color:#c0c40a;"
                          >{{ mapStatusInvoiceName(formatterInvoiceStatus) }}</p>
                        </div>
                      </div>
                      <div class="flex items-center ml-2 mr-2">
                        <i class="el-icon-money" style="font-size: 40px; color: #20409B;" />
                        <p class="fs-20 ml-2 mb-0" style="color: #424242;">Đơn giá</p>
                        <p
                          class="fs-20 ml-auto p-2 mb-0"
                          style="color: red;"
                        >{{ appTInfo.slot_price ? appUtils.numberFormat(appTInfo.slot_price, 'VNĐ') : 'Hỗ trợ' }}</p>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row
              class="justify-content-center pt-3 mb-5"
              v-if="appTInfo.status !== APPOINTMENT_STATUS.CODE.CLINIC_REJECTED && appTInfo.status !== APPOINTMENT_STATUS.CODE.PATIENT_CANCELLED"
            >
              <b-col v-if="formatterInvoiceStatus === 1" cols="12" md="6" class="mb-2">
                <b-button
                  :to="{ path: `/detail-invoice-appointment/${appTInfo.invoice.id}`, query: { token: appTInfo.invoice.token, appTId: appTInfo.id }}"
                  style="width: 100%; background-color: #20419B;"
                >Thanh toán</b-button>
              </b-col>
              <!-- <b-col v-if="formatterInvoiceStatus===3" cols="12" md="6" class="mb-2">
                <b-button
                  @click="checkIn()"
                  style="width: 100%; background-color: #20419B;"
                >Lấy số thứ tự vào phòng khám</b-button>
              </b-col>-->
              <b-col cols="12" md="6" class="mb-2">
                <b-button
                  @click="downloadVisualReport"
                  style="width: 100%; background-color: #20419B;"
                  :disabled="isDownloading"
                >
                  <b-spinner class="me-1" small v-if="isDownloading"></b-spinner>
                  <span>{{ formatterInvoiceStatus === 3 || formatterInvoiceStatus === 2 ? 'In phiếu đăng ký khám' : 'In phiếu thanh toán tại quầy Lễ tân'}}</span>
                </b-button>
              </b-col>
            </b-row>

            <div class="text-center fixed top-0 left-0" style="z-index: -1;">
              <div class="p-4 max-w-screen-sm mx-auto" ref="printMe">
                <PrintQRCode :appTInfo="appTInfo" :title="qrCodeTitle" />
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BButton, BOverlay } from 'bootstrap-vue'
import ModalQRCode from './modal/QRCode.vue'
import { required } from '../../../../src/utils/validations/validations'
import 'vue-select/dist/vue-select.css'
import appUtils from '../../../utils/appUtils'
import moment from 'moment'
import { ref, onBeforeMount, onBeforeUnmount } from '@vue/composition-api'
import router from '../../../router'
import store from '../../../store'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import { APPOINTMENT_STATUS } from '../../../utils/constant'
import QRCode from 'qrcode.vue'
import html2canvas from 'html2canvas'
import PrintQRCode from './PrintQRCode.vue'
import { saveAs } from 'file-saver'

export default {
  name: 'MedicalRegisterScheduleDetail',
  components: {
    ModalQRCode,
    QRCode,
    BCard,
    BRow,
    BCol,
    BButton,
    BOverlay,
    PrintQRCode
  },
  computed: {
    ...mapGetters('app', ['invoiceStatus']),
    qrCodeTitle () {
      return this.appTInfo?.invoice?.status === 3 ||
        this.appTInfo?.invoice?.status === 2
        ? 'Dùng mã này để xác nhận tại quầy/phòng khám'
        : 'Dùng mã này để thanh toán tại quầy Lễ tân'
    },
    formatterInvoiceStatus () {
      return this.appTInfo?.invoice?.status
    }
  },
  data () {
    return {
      required,
      isShowQr: false,
      appUtils,
      APPOINTMENT_STATUS,
      isDownloading: false
    }
  },
  setup () {
    const userData = ref({})
    const loading = ref(false)
    const appTInfo = ref(null)
    const apptId = router.currentRoute.params.id
    const interval = ref(null)

    const fetchAppointMent = (isLoading = true) => {
      loading.value = !!isLoading
      store
        .dispatch('registerMedical/fetchAppointMent', { id: apptId })
        .then(response => {
          appTInfo.value = response.data.data
          loading.value = false
          const { patient_visit } = response.data.data
          if (patient_visit?.id) {
            router.replace({
              path: `/detail-patients-visit/${patient_visit?.id}`
            })
          }
        })
        .catch(e => {
          console.log(e)
          window.$toast.open({
            message: 'Lỗi lấy thông tin lịch khám',
            type: 'danger'
          })
          loading.value = false
        })
    }
    const checkIn = () => {
      const dateFm = 'yyyy/MM/DD'
      if (
        moment.utc(appTInfo.value.start_time).format(dateFm) !==
        moment().format(dateFm)
      ) {
        // check can checkin 24h
        Vue.prototype.$alert('Chỉ được lấy số thứ tự trong ngày', 'Thông báo')
      } else {
        loading.value = true
        const payload = {
          appt_id: appTInfo.value.id,
          clinic_id: appTInfo.value.clinic_id,
          cr_id: appTInfo.value.cr_id,
          doctor_id: appTInfo.value.doctor_id,
          person_id: appTInfo.value.person_id,
          start_time: window.moment
            .utc(appTInfo.value.start_time)
            .format('YYYY-MM-DD HH:mm:ss'),
          end_time: window.moment
            .utc(appTInfo.value.end_time)
            .format('YYYY-MM-DD HH:mm:ss'),
          method: 1,
          visit_reason: appTInfo.value.appt_reason,
          checkin_time: moment()
        }
        console.log(payload)
        store
          .dispatch('registerMedical/createPatientVisit', payload)
          .then(response => {
            console.log('patient visit')
            console.log(response)
            loading.value = false
            const ptId = response.data.data.id
            router.replace({ path: `/detail-patients-visit/${ptId}` })
          })
          .catch(e => {
            console.log(e)
            loading.value = false
            window.$toast.open({
              message: 'Lỗi lấy thông tin lịch khám',
              type: 'danger'
            })
          })
      }
    }

    onBeforeMount(() => {
      fetchAppointMent()
      interval.value = setInterval(() => {
        fetchAppointMent(false)
      }, 1000 * 30)
    })

    onBeforeUnmount(() => {
      clearInterval(interval.value)
      interval.value = null
    })

    return {
      userData,
      appTInfo,
      checkIn,
      loading,
      interval
    }
  },
  methods: {
    mapStatusInvoiceName (value) {
      return this.invoiceStatus.find(x => x.value === value)?.name
    },
    downloadVisualReport () {
      try {
        this.isDownloading = true
        html2canvas(this.$refs.printMe)
          .then(canvas => {
            canvas.toBlob(blob => {
              saveAs(blob, `LH-${this.appTInfo.code}.png`)
            })
            this.isDownloading = false
            // const image = canvas.toDataURL('image/png')
            // // .replace('image/png', 'image/octet-stream')
            // const link = document.createElement('a')
            // if (typeof link.download !== 'string') {
            //   window.open(image)
            //   this.isDownloading = false
            // } else {
            //   link.href = image
            //   link.download = `LH-${this.appTInfo.code}.png`
            //   this.trickForFirefox(this.clickLink, link)
            // }
          })
          .catch(_error => {
            console.log(_error)
            this.isDownloading = false
          })
      } catch (error) {
        console.log(error)
        this.isDownloading = false
      }
    },
    clickLink (link) {
      link.click()
    },
    trickForFirefox (click) {
      const link = arguments[1]
      document.body.appendChild(link)
      click(link)
      document.body.removeChild(link)
      this.isDownloading = false
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>
<style scoped>
.form {
  justify-content: center;
  display: flex;
  align-items: center;
  min-height: 100vh;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.transition {
  width: 18px;
  transition: transform 0.1s ease-in-out;
}
.text-box-success {
  background: rgba(19, 219, 191, 0.15);
  border: 1px solid #08b58b;
  border-radius: 6px;
}
.text-box-pending {
  background: rgba(219, 199, 19, 0.15);
  border: 1px solid #c0c40a;
  border-radius: 6px;
}
.text-box-fail {
  background: rgba(216, 0, 0, 0.18);
  border: 1px solid #d80000;
  border-radius: 6px;
}
</style>
