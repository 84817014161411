<template>
  <div class="text-center">
    <h3>Phiếu đăng ký khám</h3>
    <p class="fs-16">{{ title }}</p>
    <Qrcode v-if="appTInfo && appTInfo.code" :value="`LH-${appTInfo && appTInfo.code}`" :size="200"></Qrcode>
    <b-alert show variant="danger" class="text-center" v-else>
      <div>Hệ thống không tìm thấy mã QR Code.</div>
      <div>Vui lòng thử tải lại trang web.</div>
    </b-alert>
    <div class="fs-30 mb-2">{{ `LH-${appTInfo && appTInfo.code}` }}</div>
    <div class="border uppercase text-center p-2 mb-2">
      <div>SỐ THỨ TỰ</div>
      <div class="text-3xl font-bold">{{ appTInfo && appTInfo.line_number }}</div>
    </div>
    <div>
      <div class="mb-2">
        <div class="flex items-center">
          <i class="el-icon-money" />
          <p
            class="ml-2 mb-0 uppercase font-bold"
          >{{appTInfo.invoice && appTInfo.invoice.status === 3 ? $t('Đã thanh toán') : $t('Chưa thanh toán')}}</p>
        </div>
        <div class="text-left">
          <span>{{ $t('Số tiền') }}: {{ appTInfo.invoice && appTInfo.invoice.price > 0 ? appUtils.numberFormat(appTInfo.invoice.price, 'VNĐ') : $t('Hỗ trợ') }}</span>
          <span
            class="ml-2"
            v-if="appTInfo.invoice && appTInfo.invoice.status === 3"
          >{{ appTInfo.invoice && appTInfo.invoice.payment_date ? appUtils.formatTimeDate(appTInfo.invoice.payment_date) : '' }}</span>
        </div>
      </div>
      <div class="flex items-center mb-2">
        <i class="el-icon-school" />
        <p class="ml-2 mb-0">{{appTInfo.clinic.name}}</p>
      </div>
      <div class="flex items-center mb-2">
        <i class="el-icon-first-aid-kit" />
        <p class="ml-2 mb-0">{{appTInfo.consulting_room.name}}</p>
      </div>
      <div class="flex items-center mb-2">
        <i class="el-icon-location-information" />
        <p class="ml-2 mb-0">{{appTInfo.clinic.address}}</p>
      </div>
      <div class="flex items-center">
        <i class="el-icon-date" />
        <div class="ml-2 mb-0">
          <p class="mb-0">{{appUtils.formatGroupDateTime(appTInfo.start_time)}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Qrcode from 'qrcode.vue'
import appUtils from '../../../utils/appUtils'

export default {
  name: 'PrintQRCode',
  props: {
    appTInfo: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      appUtils
    }
  },
  components: { Qrcode }
}
</script>

<style>
</style>
